<template>
  <div class="login-page">
    <div class="login-box">
      <img src="@/assets/images/logo.webp" alt="">
      <div class="loginContent">
          <p class="tip">后台管理系统</p>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm" label-width="0px" style="width:70%;margin:0 auto;">
            <el-form-item label="" prop="account" class="inputLogin">
              <el-input placeholder="请输入您的账号" v-model.trim="ruleForm.account" autocomplete="off" @keyup.enter.native="handleSubmit">
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="password" class="inputLogin">
              <el-input type="password" placeholder="请输入账户密码" v-model.trim="ruleForm.password" autocomplete="off" @keyup.enter.native="handleSubmit">
              </el-input>
            </el-form-item>
            <p class="loginbtn" @click="handleSubmit">登 录</p>
          </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        account: "",
        password: "",
      },
      rules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      }
    };
  },
  created() {
    sessionStorage.clear()
    localStorage.clear()
  },
  methods: {
    handleSubmit() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          let res = await this.$http.login({...this.ruleForm});
          if (res) {
            const token = res.data.token;
            sessionStorage.setItem('token','Bearer '+token)
            sessionStorage.setItem('userInfo',res.data)
            // window.location.href = '/'
            this.getUserMenuFunc()
          } else {
            this.$message.error('账户或者密码错误！')
          }
        }
      });
    },
    async getUserMenuFunc() {
      let res = await this.$http.menuListAll();
      if (res) {
        const data = res.data
        let url = ''
        if (data?.length==0){
          this.$message.error('该账户没有权限，请联系管理员！')
          return
        } else if (data[0].menuLists&&data[0].menuLists.length>0) {
          url = data[0].menuLists[0].href
        } else if (!data[0].menuLists || data[0].menuLists?.length==0) {
          url = data[0].href
        }
        this.$router.push({path:url})
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: #f0f2f5 url(../assets/images/background.svg) no-repeat 50%;
  // background-size: 100% 100%;
  background: linear-gradient(to bottom, #19778c, #095f88);
    .login-box {
      width: 540px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      img{
        width: 220px;
        text-align: center;
      }
      .loginContent{
        width: 100%;
        border-radius: 5px;
        background: rgba(38,39,51,0.3);
        color: #fff;
        padding: 32px;
      }
      .tip{
        font-size: 30px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
        margin-bottom: 20px;
      }
      .title{
        font-size: 20px;
        color:#209e91;
        text-align: center;
        margin-bottom: 20px;
        .logo{
          display: inline-block;
          width: 40px;
          height: 20px;
          background: url('../assets/images/logo.png') no-repeat;
          background-size: 100% 100%;
          position: relative;
          top: 2px;
          right: 20px;
        }
      }
      input:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f7f9fb inset;
      }
      .icon {
        position: absolute;
        left: 0;
        top: 8px;
      }
      .icon1 {
        width: 25px;
        height: 30px;
        background-size: contain;
      }
      .icon2 {
        width: 22px;
        height: 29px;
        background-size: contain;
      }
      .loginbtn {
        background: #209e91;
        border-radius: 8px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 13px;
        margin: 20px auto 0;
        display: block;
        color: #fff;
        cursor: pointer;
        transition: all .1s;
        &:hover{
          opacity: 0.8;
        }
      }
      .authCodeBox{
        display: flex;
        align-content: center;
        .authCodeInput{
          flex: 1;
        }
        .authCode{
          width: 100px;
          margin-left: 20px;
          img{
            width: 100%;
            height: 50px;
          }
        }
      }
    }
}
</style>
<style lang="scss">
.login-page .el-form-item.is-error .el-input__inner{
  border-color: #8e9198;
}
.login-page .el-form-item__error {
    color: #F56C6C !important;
}
.inputLogin {
  height: 60px;
}
.inputLogin input{
  border: none;
  outline: none;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  border-radius: 5px;
}
.loginContent{
  .el-form .el-input{
    width: 100% !important;
  }
}
</style>